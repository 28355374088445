<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" width="300">
        <ykc-form-item prop="pileIds" label="桩编码">
          <ykc-tree
            ref="operaTree"
            width="578"
            :data="gunCodeData"
            :props="treeProps"
            :nodeKey="treeNodeKey"
            @check-change="handleOperatorTree"></ykc-tree>
        </ykc-form-item>
        <ykc-form-item label="目标站点" prop="targetStationId">
          <ykc-dropdown
            :clearable="true"
            :data="brandListData"
            v-model="ruleForm.targetStationId"></ykc-dropdown>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { directPage, barrierPage } from '@/service/apis';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        brandListData: [],
        gunCodeData: [],
        treeNodeKey: 'id',
        treeProps: {
          label: 'name',
          children: 'children',
          disabled: 'disabled',
        },
        ruleForm: {
          targetStationId: '',
          pileIds: [],
        },
        rules: {
          targetStationId: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          pileIds: [{ required: true, message: '请选择桩编码', trigger: 'blur' }],
        },
      };
    },
    created() {
      this.requestBrandList();
      this.requestAllList();
    },

    methods: {
      /**
       * @desc 选择
       * */
      handleOperatorTree(e) {
        console.log(JSON.stringify(e));
        const arr = [];
        e.map(item => {
          return arr.push(Number(item.split('-')[0]));
        });
        this.ruleForm.pileIds = [...new Set(arr)];
        console.log(this.ruleForm.pileIds);
      },
      /**
       * 获取桩编码列表
       */
      requestAllList() {
        const reqParams = {
          size: 999999999,
          current: 1,
          isTestStation: 1,
        };
        directPage
          .dirFindByPage(reqParams)
          .then(res => {
            this.gunCodeData = [];
            res.records.map(item => {
              return this.gunCodeData.push({
                id: `${item.pileId}-${item.gunCode}`,
                name: item.gunCode,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属电站列表
       */
      requestBrandList() {
        barrierPage
          .findPriOrPubStations({ type: 1, isPrivatePile: 0 })
          .then(res => {
            console.log('获取归属电站列表+++++', res);
            const params = res;
            params.map(item => {
              return this.brandListData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              const data = {
                targetStationId: this.ruleForm.targetStationId,
                pileIds: this.ruleForm.pileIds,
              };

              directPage
                .testPileChange(data)
                .then(res => {
                  this.$message.success('迁桩成功');
                  resolve();
                })
                .catch(err => {
                  console.log(err);
                });
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>
